<template>
  <CHeader fixed with-subheader light>
    <CToggler in-header class="ml-3 d-lg-none" @click="$store.commit('toggleSidebarMobile')" />
    <CToggler in-header class="ml-3 d-md-down-none" @click="$store.commit('toggleSidebarDesktop')" />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
      <CIcon name="logo" height="48" alt="Logo" />
    </CHeaderBrand>

    <div v-if="property.id && property.building_list.length > 1" class="w-75 mx-auto">
      <AMultiSelect name="building" :value="building_id" class="mt-2 mb-0 w-50 float-right" placeholder="Search building" :options="property.building_list" :config="{ icon: 'x-search', refresh: true }" @input="gotoBuilding" />
    </div>

    <AMenu />

    <CHeaderNav class="mr-4">
      <!--CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-bell" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-list" />
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <CIcon name="cil-envelope-open" />
        </CHeaderNavLink>
      </CHeaderNavItem-->
      <CHeaderNavItem v-if="periods._status" class="d-md-down-none mx-2">
        <CHeaderNavLink>
          {{ `${periods._status}: ` }}
          {{ periods._period_name }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <span v-if="properties.length" style="cursor: pointer;" @click="$router.push({ name: 'Property selector' })">
        <CIcon name="cil-view-module" size="xl" />
      </span>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
    <CSubheader class="px-3">
      <ABreadcrumbRouter class="border-0 mb-0" />
      <div v-if="topActions" class="ml-5 pt-2 top-actions">
        <CButton v-for="(item, index) of topActions" :key="index" :class="item.class" :disabled="item.disabled" @click="item.click">{{ item.content }}</CButton>
      </div>
    </CSubheader>
  </CHeader>
</template>

<script>
import viewMixin from '@/app/views/_mixins/view-mixin'
import AMenu from '@/app/components/containers/AMenu'
import ABreadcrumbRouter from '@/app/components/breadcrumb/ABreadcrumbRouter'
import TheHeaderDropdownAccnt from '@/app/components/containers/TheHeaderDropdownAccnt.vue'

export default {
  name: 'TheHeader',
  mixins: [viewMixin],
  components: {
    AMenu,
    ABreadcrumbRouter,
    TheHeaderDropdownAccnt
  },
  computed: {
    topActions() {
      const actions = this.$store.getters['getTopActions']
      return actions ? actions[this.$route.name] : []
    },
    building_id() {
      return this.$store.getters['getHeaderBuildingId']
    },
    properties() {
      return []
      //return this.userExtra.properties || []
    }
    /*loggedUser() {
      return this.$store.getters['auth/loggedUser']
    }*/
  },
  mounted() {
    if (this.loggedUser._guard === 'no-users') {
      const building = this.property.building_list[0]
      this.gotoBuilding(building.id)
    }
  },
  methods: {
    gotoBuilding(id) {
      // TODO: Improve this
      if (this.loggedUser._guard === 'no-users') {
        this.$store.dispatch('setHeaderBuildingId', id)
      } else {
        if (id) this.$router.push({ name: 'BuildingDashboard', params: { id } })
        else this.$store.dispatch('setHeaderBuildingId', '')
      }
    }
  }
}
</script>

<template>
  <CSidebar fixed :minimize="minimize" :show="show" @update:show="value => $store.commit('set', ['sidebarShow', value])">
    <CSidebarBrand class="d-md-down-none" :to="loggedUser && loggedUser.property ? `/admin/properties/${loggedUser.property.id}/edit` : '/'">
      <div v-if="loggedUser.property" class="d-block" name="logo" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
        <img :src="logo || empty_image" @error="e => (e.target.src = empty_image)" name="logo" height="48" alt="Logo" />
        <span v-if="!minimize" class="font-2xl"> {{ loggedUser.property.short_name || loggedUser.property.name }}</span>
      </div>
      <CIcon v-else class="d-block" name="logo" size="custom-size" :height="35" :viewBox="`0 0 ${minimize ? 110 : 556} 134`" />
    </CSidebarBrand>

    <div v-if="!minimize && properties.length > 1" @click="show_properties = !show_properties" style="text-align: center; cursor: pointer;">
      <CIcon class="mx-2 font-2xl" :name="show_properties ? 'cilChevronTop' : 'cilChevronBottom'" />
    </div>
    <CCollapse v-if="!minimize && properties.length > 1" :show="show_properties" class="c-sidebar-nav h-100" style="position: relative; overflow-y: auto;">
      <CDropdownItem @click="gotoProperty({ id: 0 })">
        <img :src="logo || empty_image" @error="e => (e.target.src = empty_image)" name="logo" height="48" alt="Logo" />
        <span v-if="!minimize" class="ml-2 font-2xl"> {{ '- Reset -' }}</span>
      </CDropdownItem>
      <CDropdownItem v-for="property of properties" :key="property.id" @click="gotoProperty(property)">
        <img :src="property.logo || empty_image" @error="e => (e.target.src = empty_image)" name="logo" height="48" alt="Logo" />
        <span v-if="!minimize" class="ml-2 font-2xl"> {{ property.short_name || property.name }}</span>
      </CDropdownItem>
    </CCollapse>

    <CRenderFunction flat :key="'_menu_' + (nav || []).length" :content-to-render="nav || []" />

    <CSidebarMinimizer class="d-md-down-none" @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
  </CSidebar>
</template>

<script>
export default {
  name: 'TheSidebar',
  computed: {
    nav() {
      return this.$store.getters['menu/getMenuSidebar']
    },
    logo() {
      if (this.loggedUser.property && this.loggedUser.property.logo_url) return this.loggedUser.property.logo_url
      return false
    },
    loggedUser() {
      return this.$store.getters['auth/loggedUser'] || {}
    },
    show() {
      return this.$store.state.sidebarShow
    },
    minimize() {
      return this.$store.state.sidebarMinimize
    },
    userExtra() {
      return this.$store.getters['auth/userExtraData'] || {}
    },
    properties() {
      return this.userExtra.properties || []
    }
  },
  data() {
    return {
      empty_image: require('@/assets/images/global/empty-image.jpg'),
      show_properties: false
    }
  },
  mounted() {
    // console.log('HERE1', this.loggedUser)
    // setTimeout(() => {
    //   console.log('HERE', this.loggedUser)
    //   /*if (this.nav === false) {
    //     this.$store.dispatch('menu/getMenu', 'sidebar')
    //   }*/
    // }, 5000)

    //this.empty_image = require('@/assets/images/global/empty-image.jpg')

    if (this.nav === false) {
      this.$store.dispatch('menu/getMenu', 'sidebar')
    }
  },
  methods: {
    gotoProperty(property) {
      const self = this
      self.show_properties = false
      self.$http.post(`admin/properties/set/${property.id}`, { id: property.id }).then(response => {
        console.log(response)
        self.$router.push({ name: property.id ? 'Dashboard' : 'Property selector' })
      })
    }
  }
}
</script>

<style lang="scss">
.c-sidebar-nav-divider {
  height: 1px !important;
  //margin: 0 40px;
  padding: 0;
  background: #485871;
  margin-left: 50px;
  line-height: 2px;
}
</style>

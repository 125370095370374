<template>
  <CToaster :autohide="8000">
    <template v-for="(alert, index) in alerts.filter(item => !item.shown)">
      <CToast :show="true" :color="alert.type" :key="'AT' + index" :header="$t(_.capitalize(alert.type))"> {{ alert.message }} </CToast>
    </template>
  </CToaster>
</template>

<script>
export default {
  name: 'AToaster',
  computed: {
    alerts() {
      return this.$store.getters['getAlerts']
    }
  },
  mounted() {
    this.$store.dispatch('setAlert', false) // TODO: Workaround (All are reloaded every time)
  }
}
</script>

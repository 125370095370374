<template>
  <div class="c-app" :class="{ 'c-dark-theme': debug }">
    <TheSidebar />
    <CWrapper>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
            <pre v-if="debug">{{ no_messages }}</pre>
          </CContainer>
        </main>
        <TheFooter />
      </div>
    </CWrapper>
    <AActivityIndicator />
    <AToaster />
  </div>
</template>

<script>
import TheHeader from './TheHeader.vue'
import TheSidebar from './TheSidebar.vue'
import TheFooter from '@/vendor/coreui/containers/TheFooter.vue'

import AToaster from './AToaster'
import AActivityIndicator from './AActivityIndicator'

import { DEBUG } from '@/config/config'

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    AToaster,
    AActivityIndicator
  },
  computed: {
    debug() {
      return DEBUG
    }
  },
  data() {
    return {
      timeout: false,
      no_messages: {}
    }
  },
  updated() {
    this.timeout = false
    this.timeout = setTimeout(() => {
      const _no_messages = this._no_trans()
      const keys = Object.keys(_no_messages)
      if (keys.length) {
        keys.sort().reduce((obj, key) => {
          if (key) this.$set(this.no_messages, key, _no_messages[key])
        })
      }
    }, 1000)
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/*.c-main {
  overflow: auto;
}*/
</style>

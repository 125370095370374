<template>
  <div v-if="isProcessing" class="a-processing">
    <transition name="fade">
      {{ $t('Loading') }}....
      <div class="a-spinner-container">
        <div class="a-spinner" :class="config.spinner.class">
          <div v-for="(item, index) of config.spinner.items" :key="'_p' + index" class="a-spinner-item" :class="item.class" />
        </div>
      </div>
      {{ _processes }}
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AActivityIndicator',
  computed: {
    config() {
      return this.$store.getters['getConfig']
    },
    isProcessing() {
      return this.$store.getters['isProcessing']
    },
    _processes() {
      return this.$store.getters['getProcesses']
    }
  }
}
</script>

<style src="spinkit/spinkit.min.css" />

<template>
  <CHeaderNav class="d-md-down-none mr-auto">
    <CHeaderNavItem v-for="n in nav || []" v-bind:key="n.name">
      <CHeaderNavLink v-if="n._name == 'CSidebarNavItem'" :href="'#' + n.href">
        {{ this.$t(n.name) }}
      </CHeaderNavLink>
      <CDropdown v-if="n._name == 'CSidebarNavDropdown'" color="link" :caret="false">
        <template #toggler-content>
          <a class="c-header-nav-link">
            {{ this.$t(n.name) }}
          </a>
        </template>
        <CDropdownItem v-for="d in n._children" v-bind:key="d.name" :href="'#' + d.to">
          {{ this.$t(d.name) }}
        </CDropdownItem>
      </CDropdown>
    </CHeaderNavItem>
  </CHeaderNav>
</template>

<script>
export default {
  name: 'AMenu',
  computed: {
    nav() {
      return this.$store.getters['menu/getMenuTop']
    }
  },
  mounted() {
    if (this.nav === false) {
      this.$store.dispatch('menu/getMenu', 'top')
    }
  }
}
</script>

<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img :src="loggedUser.photo_url || empty_avatar" @error="e => (e.target.src = empty_avatar)" class="c-avatar-img" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>{{ $t('Settings') }}</strong>
    </CDropdownHeader>
    <template v-if="properties.length">
      <CDropdownItem @click="$router.push({ name: 'Property selector' })">
        <CIcon name="cil-view-module" />
        {{ $t('Property selector') }}
      </CDropdownItem>
      <CDropdownDivider />
    </template>
    <CDropdownItem>
      <CIcon name="cil-user" />
      {{ $t('Profile') }}
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-settings" />
      {{ $t('Settings') }}
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem>
      <CIcon name="cil-shield-alt" />
      {{ $t('Lock account') }}
    </CDropdownItem>
    <CDropdownItem @click="logout()">
      <CIcon name="cil-lock-locked" />
      {{ $t('Logout') }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  computed: {
    loggedUser() {
      return this.$store.getters['auth/loggedUser'] || {}
    },
    properties() {
      return (this.$store.getters['auth/userExtraData'] || {}).properties || []
    }
  },
  data() {
    return {
      empty_avatar: require('@/assets/images/global/avatar.png')
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    }
  }
}
</script>
<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
